import { groupBy, sortBy, merge } from 'lodash';
import breakpoints from '../breakpoints';

if( typeof mapboxgl !== 'undefined' ) {
  mapboxgl.accessToken = process.env.MAPBOX_TOKEN;
}
class Locations {
  constructor( container, options ){
    this.settings = merge({}, Locations.DEFAULTS, options);
    this.container = container;
    this.map = null;
    this.popup = null;
    this.overlay = null;
    this.filters = null;
    this.categories = [];
    this.markers = {};
    this.currentMarker = null;

    this.handleFilterClick = this.handleFilterClick.bind(this);
    this.handleActionClick = this.handleActionClick.bind(this);
    this.handleZoom = this.handleZoom.bind(this);

    this.init();
  }

  init() {
    const { mapOptions, selectors } = this.settings;

    this.container.style.setProperty('--screen-height', window.innerHeight + "px");
    this.overlay = this.container.querySelector( selectors.overlay );
    this.filters = this.container.querySelector( selectors.filters );

    this.map = new mapboxgl.Map({
      container: this.container.querySelector( selectors.map ),
      ...mapOptions
    });

    this.map.on('load', () => {
      this.map.addLayer({
        id: 'provinces',
        source: {
          type: 'vector',
          url: 'mapbox://castletoncommodities.2tj305ox', // tile set ID
        },
        'source-layer': 'ne_10m_admin_1_states_provinc-bes0eb', // source id layer name
        type: 'fill',
        paint: {
          'fill-color': '#5dbec5',
          'fill-outline-color': '#5dbec5',
          'fill-opacity': 0.3
        },
      });

      this.map.setFilter(
        'provinces',
        ['in', 'adm1_code'].concat([]),
      );
    }); 

    this.map.addControl(new mapboxgl.NavigationControl({ showCompass: false }), 'bottom-right');
    this.popup = new mapboxgl.Popup({ closeButton: false });

    this.bindHandlers();
  }

  bindHandlers() {
    this.filters.addEventListener('click', this.handleFilterClick);
    this.container.addEventListener('click', this.handleActionClick);
    this.map.on('zoom', this.handleZoom);
  }

  addMarkers({ categories, data }){
    this.categories = [ ...this.categories, ...categories];

    const zIndexes = sortBy(this.categories, cat => cat.zIndex).map(cat => cat.id);
    let groups = groupBy(data.features, item => item.properties.catId);
    
    // Add cat id as prop to array as it is lost during sort
    Object.keys(groups).forEach(key => {
      Object.defineProperty( groups[key], "catId", { value: key, enumerable: false, writable: true } );
    });

    groups = sortBy(groups, group => zIndexes.indexOf(group[0].properties.catId));

    groups.forEach(group => {
      const markers = [];

      group.forEach(({ geometry, properties }) => {
        const { icon, iconAnchor } = this.categories.find(cat => cat.id === properties.catId);
        const element = document.createElement('div');
        
        element.className = 'marker';
        element.setAttribute('data-icon', icon);
        element.setAttribute('data-hash', this.slugify(properties.title));

        const marker = new mapboxgl.Marker({
          element,
          anchor: iconAnchor || "bottom"
        })
        .setLngLat(geometry.coordinates)
        .addTo(this.map);
        
        marker.properties = properties;
        
        element.addEventListener('mouseover', this.openPopup.bind(this, marker) );
        element.addEventListener('mouseleave', this.closePopup.bind(this) );
        element.addEventListener('click', this.handleMarkerClick.bind(this, marker) );

        markers.push( marker );
      });

      this.markers[group.catId] = markers;
    });

    this.renderFilters();

    // try to open overlay if a hash exists
    if(location.hash !== '') {
      this.container
        .querySelector(`[data-hash="${location.hash.substring(2)}"]`)?.click();      
    }
  }

  renderFilters(){
    let filters = this.categories.map( cat => {
      return this.settings.templates.filter( cat );
    });

    filters = [
      this.settings.templates.filter({ "id": -1, "title": "All", "icon": null }),
      ...filters
    ];

    this.filters.innerHTML = filters.join('');
  }

  handleMarkerClick(marker, e) {
    e.stopPropagation();

    this.currentMarker?.getElement().classList.remove('is-active','is-large');
    marker.getElement().classList.add('is-active','is-large');
    this.currentMarker = marker;
    
    this.closePopup();
    this.openOverlay();
  }

  handleActionClick(e) {
    const btnAction = e.target.closest('[data-action]');

    if( !btnAction ) return;

    const targetId = btnAction?.getAttribute('aria-controls');
    let isExpanded = btnAction?.getAttribute('aria-expanded');

    e.preventDefault();

    if (btnAction?.dataset?.action === 'open-overlay') {
      this.openOverlay();
      isExpanded = true;
    }

    if (btnAction?.dataset?.action === 'close-overlay') {
      this.closeOverlay();
      isExpanded = false;
    }

    if (typeof isExpanded !== 'undefined') {
      document.querySelectorAll(`[aria-controls*="${targetId}"]`).forEach(el => {
        el.setAttribute('aria-expanded', isExpanded);        
      });
    }
  }

  handleFilterClick(e) {
    e.stopPropagation();

    const checkbox = e.target.closest('input[type="checkbox"][data-cat-id]');

    if (!checkbox) return;

    const data = checkbox.dataset;
    const id = Number(data.catId);

    if ( checkbox.checked ) {
      this.addLayer( id );
    } else {
      this.removeLayer( id );
    }
  }

  handleZoom() {
    if( !this.currentMarker ) return;

    const zoom = this.map.getZoom();
    const markerElem = this.currentMarker?.getElement();

    if( zoom >= 6 ) {
      markerElem.classList.add('is-large');
    } else {
      markerElem.classList.remove('is-large');
    }
  }

  addLayer( id ){
    if( id === -1 ) {
      Object.keys(this.markers).forEach( id => {
        this.addLayer( id );
      }); 
    } else {
      this.markers[ id ]?.forEach( marker => {
        marker.addTo(this.map);
      });

      const all = this.filters.querySelector(`input[type="checkbox"][data-cat-id="-1"]`);
      const inputs = Array.from( this.filters.querySelectorAll(`input[type="checkbox"][data-cat-id]:not([data-cat-id="-1"])`) );
      const input = this.filters.querySelector(`input[type="checkbox"][data-cat-id="${id}"]`);
      const allSelected = inputs.every( input => input.checked === true );
      
      if( input ) {
        input.checked = true;
      }
      
      if( allSelected ) {
        all.checked = true;
      }
    }
  }

  removeLayer( id ){
    if( id === -1 ) {
      Object.keys(this.markers).forEach( id => {
        this.removeLayer( id );
      });
    } else {
      this.markers[ id ]?.forEach( marker => {
        marker.remove();
      });

      const all = this.filters.querySelector(`input[type="checkbox"][data-cat-id="-1"]`);
      const input = this.filters.querySelector(`input[type="checkbox"][data-cat-id="${id}"]`);
      
      if( input ) {
        input.checked = false;
      }

      if( all ) {
        all.checked = false;
      }      
    }
  }

  openOverlay(){
    const { breakpoint, mapActiveMarkerPadding } = this.settings;
    const props = this.currentMarker.properties;

    this.setOverlayContent( props );
    this.overlay.removeAttribute('hidden');

    this.map.flyTo({
      zoom: 7,
      center: this.currentMarker.getLngLat(),
      padding: window.innerWidth >= breakpoint ? mapActiveMarkerPadding : 0
    });
    
    this.container.classList.add('has-overlay');

    if( window.innerWidth < breakpoint ) {
      document.body.style.setProperty('overflow', 'hidden');
      document.body.style.setProperty('position', 'fixed');
    }

    if( props.province ) {
      this.map.setFilter(
        'provinces',
        ['in', 'adm1_code'].concat([ props.province ]),
      );
    }

    history.replaceState(null, null, `#/${this.slugify(props.title)}`);
  }

  closeOverlay(){
    this.overlay.setAttribute('hidden', '');
    this.currentMarker.getElement().classList.remove('is-active','is-large');

    this.map.flyTo({
      zoom: this.settings.mapOptions.zoom,
      center: this.settings.mapOptions.center,
      padding: { left: 0 }
    });

    this.map.setFilter(
      'provinces',
      ['in', 'adm1_code'].concat([]),
    );

    this.container.classList.remove('has-overlay');
    document.body.style.removeProperty('position');
    document.body.style.removeProperty('overflow');

    this.currentMarker = null;
    history.replaceState(null, null, `#`);
  }

  slugify(string) {
    return string
      .toLowerCase()
      .replace(/ /g, '-')
      .replace(/[^\w-]+/g, '');
  }

  setOverlayContent( properties ){
    Object.keys( properties ).forEach( key => {
      const value = properties[ key ];
      const target = this.overlay.querySelector(`[data-key="${key}"]`);

      if( !target ) return;

      if( !value ) {
        target.setAttribute('hidden', '');
      } else {
        target.removeAttribute('hidden');
      }

      switch( target.nodeName ) {
        case 'A':
          target.setAttribute('href', value);
          break;
        case 'IMG':
          target.setAttribute('src', value);
          break;
        default:
          target.innerHTML = value;
          break;
      }
    });
  }

  closePopup(){
    this.popup.remove();
  }

  openPopup( marker ) {
    const { height } = marker.getElement().getBoundingClientRect();
    const { title, province, catId } = marker.properties;
    const { iconAnchor } = this.categories.find(cat => cat.id === catId);
    const { tooltipOffset } = this.settings;
    const offset = iconAnchor === "center" ? (height / 2) + tooltipOffset : height + tooltipOffset;

    this.popup
      .setOffset( offset )
      .setLngLat( marker.getLngLat() )
      .setHTML( title )
      .addTo( this.map );
  }
}

Locations.DEFAULTS = {
  mapActiveMarkerPadding: { left: 240 },
  tooltipOffset: 5,
  breakpoint: 768,
  mapOptions: {
    style: 'mapbox://styles/mapbox/streets-v11',
    center: window.innerWidth < breakpoints.large ? [-34.7801177, 31.9075749] : [-19.7801177, 31.9075749],
    zoom: window.innerWidth < breakpoints.medium ? 0.5 : 1.5,
    scrollZoom: false
  },
  selectors: {
    map: '.js-Locations-map',
    overlay: '.js-Locations-overlay',
    filters: '.js-Locations-filters'
  },
  templates: {
    filter: props => `
      <div class="FacetFilter">
          <input class="FacetFilter-input" type="checkbox" name="filter" id="filter-${props.id}" data-cat-id="${props.id}" checked>
          <label class="FacetFilter-label" for="filter-${props.id}">
              ${props.icon ? `
                <svg class="Icon FacetFilter-icon" aria-hidden>
                  <use xlink:href="/static/icons.svg#${props.icon}"></use>
                </svg>
              ` : ``}
              
              ${props.title}
          </label>
      </div>
    `
  }
};

(async () => {
  const container = document.querySelector( '.js-Locations' );

  if( !container) return;

  const response = await fetch('/map/data.json');
  const json = await response.json();
  
  const map = new Locations( container, {
    mapOptions: {
      style: 'mapbox://styles/castletoncommodities/cktcvo4kp0r9z17n7f16t0ogu'
    }
  });

  map.addMarkers(json);
})();