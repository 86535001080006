import Glide from '@glidejs/glide'
import ResizeSlider from './ResizeSlider';

const classes = {
  glideSlideNextActive: 'TabbedContent-panel--next-active',
};

const selectors = {
  slideNextActive: '.TabbedContent-panel--next-active',
  track: '.TabbedContent-track',
};

window.addEventListener('load', () => {
  document.querySelectorAll('.js-TabbedContent').forEach( element => {
    const instance = new Glide( element, {
      type: 'carousel',
      animationDuration: 200,
      swipeThreshold: false,
      dragThreshold: false,
      classes: {
        direction: {
          ltr: 'TabbedContent--ltr',
          rtl: 'TabbedContent--rtl'
        },
        type: {
          slider: 'TabbedContent--slider',
          carousel: 'TabbedContent--carousel'
        },
        swipeable: 'TabbedContent--swipeable',
        dragging: 'TabbedContent--dragging',
        nav: {
          active: 'TabbedContentNav-item--active'
        },
        slide: {
          active: 'TabbedContent-panel--active',
          clone: 'TabbedContent-panel--clone'
        },
        arrow: {
          disabled: 'TabbedContent-arrow--disabled'
        }
      }
    })

    instance.mount({
      ResizeSlider: (Glide, Components, Events) => {
        return ResizeSlider(Glide, Components, Events, classes, selectors)
      }
    });
  })
});