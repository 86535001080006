import merge from 'lodash/merge';
import {fetcher} from '../utils/fetcher';

class VideoPlayer {
  constructor( container ){
    this.container = container;
    this.settings = merge( {}, VideoPlayer.DEFAULTS, container.dataset );
    this.handlePlayClick = this.handlePlayClick.bind(this);

    this.init();
  }

  async init(){
    const { classNames, btnText } = this.settings;
    this.data = await fetcher.get(`https://vimeo.com/api/oembed.json?url=https://vimeo.com/${this.settings.videoId}`);

    this.container.innerHTML = `
      <img class="${classNames.thumb}" src="${this.data.thumbnail_url}" alt="${this.data.title}" loading="lazy">
      <button class="${classNames.btn}" type="button">${btnText}</button>
    `;

    this.container.querySelector('button')
      .addEventListener('click', this.handlePlayClick );
  }

  async handlePlayClick(){
    const { default: Player } = await import('@vimeo/player');
    const { video_id, width, height, title, html } = this.data;
    const { playerParameters } = this.settings;

    // const params = Object.keys(playerParameters)
    //   .map(function(key) {
    //     return key + "=" + encodeURIComponent(playerParameters[key]);
    //   })
    //   .join("&");

    // this.container.innerHTML = `
    //   <iframe src=\"https://player.vimeo.com/video/${video_id}" width="${width}" height="${height}" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="${title}"></iframe>
    // `;

    this.container.innerHTML = html;
    
    const iframe = this.container.querySelector('iframe');
    const player = new Player(iframe, playerParameters);

    player.play();
  }
}

VideoPlayer.DEFAULTS = {
  videoId: null,
  btnText: 'Watch video',
  classNames: {
    thumb: 'VideoPlayer-thumb',
    btn: 'VideoPlayer-btn'
  },
  // See https://vimeo.zendesk.com/hc/en-us/articles/360001494447-Using-Player-Parameters
  playerParameters: {
    color: 'f07a1a'
  }
};

document.querySelectorAll( '.js-VideoPlayer' )
  .forEach( element => new VideoPlayer( element ) );
