import Glide from '@glidejs/glide'

window.addEventListener('load', () => {
  document.querySelectorAll('.js-TestimonialCarousel').forEach( element => {
    new Glide( element, {
      type: 'carousel',
      classes: {
        direction: {
          ltr: 'TestimonialCarousel--ltr',
          rtl: 'TestimonialCarousel--rtl'
        },
        slider: 'TestimonialCarousel--slider',
        carousel: 'TestimonialCarousel--carousel',
        swipeable: 'TestimonialCarousel--swipeable',
        dragging: 'TestimonialCarousel--dragging',
        nav: {
          active: 'TestimonialCarousel-bullet--active'
        },
        slide: {
          active: 'TestimonialCarousel-slide--active',
          clone: 'TestimonialCarousel-slide--clone'
        },
        arrow: {
          disabled: 'TestimonialCarousel-arrow--disabled'
        }
      }
    }).mount();
  })
});