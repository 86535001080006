import Glide from '@glidejs/glide'

window.addEventListener('load', () => {
  document.querySelectorAll('.js-carousel').forEach( element => {
    new Glide( element, {
      type: 'carousel',
      classes: {
        direction: {
          ltr: 'Carousel--ltr',
          rtl: 'Carousel--rtl'
        },
        type: {
          slider: 'Carousel--slider',
          carousel: 'Carousel--carousel'
        },
        swipeable: 'Carousel--swipeable',
        dragging: 'Carousel--dragging',
        nav: {
          active: 'Carousel-navItem--active'
        },
        slide: {
          active: 'Carousel-slide--active',
          clone: 'Carousel-slide--clone'
        },
        arrow: {
          disabled: 'Carousel-arrow--disabled'
        }
      }
    }).mount();
  })
});