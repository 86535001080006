import breakpoints from "../breakpoints";
import objectParser from "../utils/object-parser";

const getVueDeps = (() => {
  let deps;
  
  async function loadDeps(){
    if( !deps ) {
      deps = await import('./imports');  
    }
    
    return deps;
  }

  return loadDeps;
})();

async function createVueComponent(component, props){
  const { vue, Vue3Mq } = await getVueDeps(); 
  const reactiveProps = vue.reactive(props);
  const instance = vue.createApp({
    setup() {
      return () => vue.h(
        component, 
        reactiveProps
      )
    }
  });      

  instance.use(Vue3Mq, {
    breakpoints
  });  

  return { instance, props: reactiveProps }
}


document.addEventListener("DOMContentLoaded", async () => {
  const placeholders = document.querySelectorAll("[data-vue-component]");

  if(!placeholders.length) return;

  const { components } = await getVueDeps();  

  placeholders.forEach(async el => {
    const {
      vueComponent: componentName,
      vueGlobals,
      ...componentProps
    } = objectParser(el.dataset);

    const app = await createVueComponent(components[componentName], componentProps)
    
    app.instance.provide('$globals', {
      ...(vueGlobals ? JSON.parse(vueGlobals) : {})
    });

    app.instance.mount(el);
  });
});

export { getVueDeps, createVueComponent };