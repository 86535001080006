import {fetcher} from "../utils/fetcher";
import xyz from "./xyz";

const lazyListing = (selector) => {
  const button = document.querySelector(selector);

  if( !button ) return;

  const config = button.dataset;
  const target = document.getElementById( config.target );
  const pagination = document.getElementById( config.pagination );
  const next = pagination.querySelector('[rel="next"]');
  
  if( !config.target ) {
    console.error( 'LazyPagination: data-target is not defined. Must be ID of content you want to load from and into.');
    return;
  } else if( !target ) {
    console.error( 'LazyPagination: target element not found, please check ID.');
    return;
  }
  
  if( !config.pagination ) {
    console.error( 'LazyPagination: data-pagination is not defined. Must be ID of pagination.');
    return;
  } else if( !pagination ) {
    console.error( 'LazyPagination: pagination not found, please check ID.');
    return;
  }
  
  if( !next ) {
    console.error( 'LazyPagination: rel="next" not found, please add to your next link.');
    return;
  }

  let nextPage = next.href;
  
  pagination.style.display = 'none';
  
  function handleClick(e) {
    e.preventDefault();  
    loadContent( nextPage, target, pagination );
  }
  
  async function loadContent(url, target){
    const response = await fetcher.get(url, { responseType: 'text' });
    const parser = new DOMParser();
    const doc = parser.parseFromString(response, 'text/html');
    const content = doc.getElementById( config.target );
    const nextPagination = doc.getElementById( config.pagination );
    const nextLink = nextPagination.querySelector('[rel="next"]');

    if( content.innerHTML ) {
      target.insertAdjacentHTML( 'beforeend', content.innerHTML );
      xyz.init();
    } 
    
    if( !nextLink ) {
      button.style.display = 'none';
    } else {
      nextPage = nextLink.href;
    }
  }
  
  button.addEventListener('click', handleClick);
}

lazyListing('.js-lazy-listing');
