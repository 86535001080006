/** 
 * Reveal animations 
 * 
 * Powered by AnimXYZ (https://animxyz.com) combined with intersection observer
 */

 const xyz = () => {
  const animatable = document.querySelectorAll('[data-xyz]');
  const h1 = document.querySelector('.js-FrontCover h1');
  
  if( h1 ) {
    const words = h1.textContent.split(' ');
  
    h1.innerHTML = words.map( (word, index) => (
      `<span style="--xyz-index: ${index}; display: inline-block;">${word}</span>`
    )).join(' ');
  }

  const observer = new IntersectionObserver(handleIntersect, {
    root: null,
    rootMargin: '0px 0px -25% 0px',
    threshold: 0
  });
  
  function isElementStaggered( element ){
    return element.getAttribute('xyz')?.indexOf('stagger') > -1 || element.getAttribute('data-xyz')?.indexOf('stagger') > -1;
  }
  
  function setStaggerClasses( parent, isActive ){
    const isParentXYZ = parent.hasAttribute('xyz') || parent.hasAttribute('data-xyz');
    const isParentStaggered = !!parent.properties?.isStaggered;
    const shouldStaggerChildren = parent.dataset.xyzStaggerChildren !== "false";

    if( !shouldStaggerChildren ) return;
    
    Array.from( parent.children ).forEach(child => {
      if( isParentXYZ && isParentStaggered ) {
        if ( isActive ) {
          child.classList.add( 'xyz-in' );
          child.classList.remove( 'xyz-out' );
        } else {
          child.classList.add( 'xyz-out' );
          child.classList.remove( 'xyz-in' );
        }
      } 
      
      if( !isParentXYZ ) {
        child.classList.add( 'xyz-nested' );
      }
  
      setStaggerClasses( child, isActive );  
    });
  }
  
  function handleIntersect( entries, observer ){
    entries.forEach((entry) => {
      const element = entry.target;
      const { isStaggered } = element.properties;

      if( entry.isIntersecting ) {
        if( isStaggered ) {
          setStaggerClasses( element, true );
        } else {
          element.classList.add( 'xyz-in' );
          element.classList.remove( 'xyz-out' );
        }
        
        if( typeof element.dataset.xyzReverse === 'undefined' ) {
          observer.unobserve(entry.target)
        }
      } else {        
        if( isStaggered ) {
          setStaggerClasses( element, false );
        } else {
          element.classList.remove( 'xyz-in' );
          element.classList.add( 'xyz-out' );
        }
      }
    });
  }
  
  // Set initial state
  animatable.forEach( element => {  
    const isStaggered = isElementStaggered( element );
    const isNested = element.parentNode.classList.contains('xyz-out');
    const isParentXYZ = element.parentNode.hasAttribute('data-xyz') || element.parentNode.hasAttribute('xyz');
    
    element.setAttribute('xyz', element.getAttribute('data-xyz'));
    element.removeAttribute('data-xyz');  
    element.properties = {
      isNested,
      isParentXYZ,
      isStaggered
    };
    
    if( isParentXYZ && !isNested ) {
      return;
    }

    if( !isStaggered ) {
      element.classList.add( isNested ? 'xyz-nested' : 'xyz-out' );
    } else {     
      setStaggerClasses( element, false );
    }   
  })
  
  animatable.forEach( element => {
    const { isNested, isParentXYZ } = element.properties;

    if( !isParentXYZ && !isNested ) {
      observer.observe( element );
    }
  });
}

export default {
  init: xyz
};