import Glide from '@glidejs/glide'
import MicroModal from 'micromodal';

window.addEventListener('load', () => {  
  document.querySelectorAll('.js-modalCarousel').forEach( element => {
    const glide = new Glide( element, {
      type: 'carousel',
      classes: {
        direction: {
          ltr: 'ModalCarousel--ltr',
          rtl: 'ModalCarousel--rtl'
        },
        type: {
          slider: 'ModalCarousel--slider',
          carousel: 'ModalCarousel--carousel'
        },
        swipeable: 'ModalCarousel--swipeable',
        dragging: 'ModalCarousel--dragging',
        nav: {
          active: 'ModalCarousel-navItem--active'
        },
        slide: {
          active: 'ModalCarousel-slide--active',
          clone: 'ModalCarousel-slide--clone'
        },
        arrow: {
          disabled: 'ModalCarousel-arrow--disabled'
        }
      }
    }).mount();

    document.addEventListener('click', e => {
      const trigger = e.target.closest('[data-modal-carousel-trigger]');

      if ( trigger ) {
        e.preventDefault();
        
        const config = trigger.dataset;

        MicroModal.show(config.modalCarouselTrigger, {
          disableScroll: true,
          disableFocus: true,
          onShow: modal => {
            glide.update({ startAt: Number(config.modalCarouselIndex) });
          },
          onClose: (modal, element, event) => {
            event.preventDefault();
            event.stopPropagation();
          }
        });
      }
    })
  })
});