class FormValidator {
  constructor( form ) {
    this.form = form;
  }
  
  initialize() {
    this.form.addEventListener('submit', e => {
      if( !this.form.checkValidity() ) {
        e.preventDefault();

        Array.from(this.form.elements).forEach( field => {
          if( field.type !== 'submit' ) {
            this.validate( field );
          }
        });
      }

      // Set focus to first invalid field
      this.form.querySelector('input:invalid').focus();
    })

    this.form.addEventListener('input', e => {
      this.validate( e.target );
    });
  }
  
  validate( field ) {
    if ( field.validity.valid ) {
      this.hideError( field );
    } else {
      this.showError( field );
    }
  }

  showError( field ) {
    const errorMessage = field.parentElement.querySelector('.js-validate-message');

    if( !errorMessage ) {
      throw Error('Message field not found.');
    }

    field.setAttribute('aria-invalid', true);
    errorMessage.innerText = field.validationMessage;
    errorMessage.removeAttribute('hidden');
  }

  hideError( field ){
    const errorMessage = field.parentElement.querySelector('.js-validate-message');

    field.setAttribute('aria-invalid', false);
    errorMessage.innerText = '';
    errorMessage.setAttribute('hidden', true);
  }
}

document.querySelectorAll('.js-validate').forEach( form => {
  const validator = new FormValidator( form );
  validator.initialize();
});
