import { on, off } from 'delegated-events';
import focusLock from 'dom-focus-lock';

class ToggleTarget {
    constructor( toggle ){
        const controls = toggle.getAttribute('aria-controls'); 

        this.settings = toggle.dataset;
        this.toggle = toggle;
        this.target = document.getElementById(controls);
        this.isExpanded = this.toggle.getAttribute('aria-expanded') === 'true';

        this.handleToggleClick = this.handleToggleClick.bind(this);
        this.handleKeydown = this.handleKeydown.bind(this)
        this.handleDocumentClick = this.handleDocumentClick.bind(this);
        this.handleCloseClick = this.handleCloseClick.bind(this)

        this.bindHandlers();
    }

    bindHandlers(){
        this.toggle.addEventListener('click', this.handleToggleClick );
        this.target.addEventListener('keydown', this.handleKeydown );
        
        if( this.settings.closeOnOutsideClick ) {
            document.addEventListener('click', this.handleDocumentClick );
        }

        on('click', '[data-action="close"]', this.handleCloseClick );
    }

    show() {
        this.toggle.setAttribute('aria-expanded', true);
        this.target.removeAttribute('hidden');
        this.target.classList.add('is-expanded');
        this.isExpanded = true;

        if( this.settings.focusLock === 'true' ) {
            focusLock.on( this.target );
        }
    }
    
    hide(){
        this.toggle.setAttribute('aria-expanded', false);
        this.target.setAttribute('hidden', true);
        this.target.classList.remove('is-expanded');
        this.isExpanded = false;

        if( this.settings.focusLock === 'true' ) {
            focusLock.off( this.target );
        }
    }

    handleCloseClick(e){
        if( !this.target.contains(e.target ) ) return;
        this.hide();
        this.toggle.focus();
    }
    
    handleKeydown(e) {
        if( e.keyCode !== 27 ) return;

        this.toggle.focus();
        this.hide();
    }

    handleDocumentClick(e) {
        const inToggle = this.toggle.contains( e.target );
        const inTarget = this.target.contains( e.target );

        if( !inToggle && !inTarget && this.isExpanded ) {
            this.hide();
        }
    }
    
    handleToggleClick(e){
        e.preventDefault();

        if( !this.isExpanded ) {
            this.show();
        } else {
            this.hide();
        }
    }

    destroy(){
        this.toggle.removeEventListener('click', this.handleToggleClick );
        this.target.removeEventListener('keydown', this.handleKeydown );
        
        if( this.settings.closeOnOutsideClick ) {
            document.removeEventListener('click', this.handleDocumentClick );
        }

        off('click', '[data-action="close"]', this.handleCloseClick );

        this.settings = null;
        this.toggle = null;
        this.target = null;
        this.isExpanded = null;
    }
}

document.querySelectorAll('.js-toggle').forEach(element => new ToggleTarget( element ));

export default ToggleTarget;