import './components/ToggleTarget';
import './components/Accordion';
import './components/FormValidator';
import './components/Slider';
import './components/Carousel';
import './components/TestimonialCarousel';
import './components/ModalCarousel';
import './components/TabbedContent';
import './components/LocationsMap';
import './components/CountUp';
import './components/VideoPlayer';
import './components/Modal';
import './components/LazyListing';
import './components/Gallery';
import './vue';

import xyz from './components/xyz';
import history from './components/history';

const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
document.body.style.setProperty('--scrollbar-width', `${scrollbarWidth}px`);


// Init AnimXYZ animations
xyz.init();
history.init();

// Make sure image are visible when printing
window.onbeforeprint = () => {
  const imgs = document.querySelectorAll('img');
  imgs.forEach(img => img.removeAttribute('loading') )
};