import Glide from '@glidejs/glide'
import { siblings } from '@glidejs/glide/src/utils/dom';
import breakpoints from '../breakpoints';

 //Automatically create bullets
const autoBullets = function (Glide, Components, Events, classes) {
  const NAV_SELECTOR = '[data-glide-el="controls[nav]"]';
  let bullets = null;

  const Component = {
      mount() {          
          bullets = Components.Html.root.querySelector(
            NAV_SELECTOR
          );

          if(!bullets) return;

          this.render();
      },

      render() {
        const { startAt, perView, classes } = Glide._o;
        const totalSlides = Components.Html.slides.length;
        const pageCount = Math.ceil(totalSlides / perView);
        const fragment = document.createDocumentFragment();
          
        for (let index = 0; index < pageCount; index++) {
          const button = document.createElement('button');
            button.classList.add(classes.nav.default);
            
            if( index === startAt ) {
              button.classList.add(classes.nav.active);
            }

            button.setAttribute('data-glide-dir', '=' + perView * index);
            button.ariaLabel = index + 1;
            fragment.appendChild(button);
        }
        
        bullets.innerHTML = '';
        bullets.appendChild(fragment);

        Components.Controls.addBindings();
      },

      setActive() {
        const bullet = bullets.querySelector(`[data-glide-dir^="=${Glide.index}"]`);
        bullet.classList.add(Glide.settings.classes.nav.active);
        
        siblings(bullet).forEach(sibling => {
          sibling.classList.remove(Glide.settings.classes.nav.active);
        });
      },
  };

  Events.on('run', () => {
    Component.setActive();
  });

  Events.on('resize', () => {
    Component.render();
  });

  return Component;
};

window.addEventListener('load', () => {
  document.querySelectorAll('.js-galleryCarousel').forEach( element => {
    new Glide( element, {
      type: 'slider',
      perView: 4,
      perSwipe: '|',
      gap: 20,
      classes: {
        type: {
          slider: 'GalleryCarousel--init',
          carousel: 'GalleryCarousel--init'
        },
        direction: {
          ltr: 'GalleryCarousel--ltr',
          rtl: 'GalleryCarousel--rtl'
        },
        swipeable: 'GalleryCarousel--swipeable',
        dragging: 'GalleryCarousel--dragging',
        nav: {
          default: 'GalleryCarousel-navItem',
          active: "GalleryCarousel-navItem--active"
        },
        slide: {
          active: 'GalleryCarousel-slide--active',
          clone: 'GalleryCarousel-slide--clone'
        },
        arrow: {
          disabled: 'GalleryCarousel-arrow--disabled'
        }
      },
      breakpoints: {
        [breakpoints.medium]: {
          perView: 2,
          peek: {
            before: 0,
            after: 40
          }
        },
      }
    }).mount({
      AutoBullets: autoBullets
    });
  })
});