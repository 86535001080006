import Glide from '@glidejs/glide'

window.addEventListener('load', () => {
  document.querySelectorAll('.js-slider').forEach( element => {
    new Glide( element, {
      type: 'carousel',
      classes: {
        direction: {
          ltr: 'Slider--ltr',
          rtl: 'Slider--rtl'
        },
        type: {
          slider: 'Slider--slider',
          carousel: 'Slider--carousel'
        },
        swipeable: 'Slider--swipeable',
        dragging: 'Slider--dragging',
        nav: {
          active: 'Slider-bullet--active'
        },
        slide: {
          active: 'Slider-slide--active',
          clone: 'Slider-slide--clone'
        },
        arrow: {
          disabled: 'Slider-arrow--disabled'
        }
      }
    }).mount();
  })
});