import { CountUp } from 'countup.js';

function countUp( element ) {
  const config = element.dataset;
  const countUp = new CountUp(element, element.innerText, {
    separator: '',
    duration: 4,
    ...config
  });

  if (!countUp.error) {
    countUp.start();
  } else {
    console.error(countUp.error);
  }
}

function handleIntersect( entries, observer ){
  entries.forEach((entry) => {
    const element = entry.target;

    if( entry.isIntersecting ) {
      countUp( element );
      observer.unobserve(entry.target)
    }
  });
}

const observer = new IntersectionObserver(handleIntersect, {
  root: null,
  rootMargin: '0px 0px -25% 0px',
  threshold: 0
});

document.querySelectorAll('.js-CountUp').forEach( element => {  
  observer.observe( element );
});