import ToggleTarget from './ToggleTarget';
import merge from 'lodash/merge';
import uniqueId from 'lodash/uniqueId';
import breakpoints from '../breakpoints';

class Accordion {
  constructor(container) {
    this.settings = merge({}, Accordion.DEFAULTS, container.dataset);
    this.container = container;
    this.items = this.container.querySelectorAll(this.settings.selectors.items);
    this.instances = [];

    this.bindHandlers();
  }

  bindHandlers() {
    if (this.settings.mq) {
      const [breakpoint, query] = this.settings.mq.split(',');
      const width =
        query === 'max-width'
          ? breakpoints[breakpoint] - 1
          : breakpoints[breakpoint];

      this.mq = window.matchMedia(`(${query}: ${width}px)`);
      this.mq.addEventListener(
        'change',
        this.handleMediaQueryChange.bind(this)
      );

      this.handleMediaQueryChange();
    } else {
      this.intialise();
    }
  }

  handleMediaQueryChange() {
    if (this.mq.matches) {
      this.intialise();
    } else {
      this.destroy();
    }
  }

  intialise() {
    const { selectors } = this.settings;

    this.items.forEach((item) => {
      const toggle = item.querySelector(selectors.toggle);
      const panel = item.querySelector(selectors.panel);
      const isExpanded = toggle.getAttribute('aria-expanded') === 'true';

      panel.id = panel.id || uniqueId('panel-');

      toggle.setAttribute('aria-expanded', isExpanded);
      toggle.setAttribute('aria-controls', panel.id);

      if (toggle.getAttribute('aria-expanded') === 'false') {
        panel.setAttribute('hidden', true);
      }

      panel.style.setProperty('maxHeight', `${panel.scrollHeight}px`);

      this.instances.push(new ToggleTarget(toggle));
    });
  }

  destroy() {
    const { selectors } = this.settings;

    this.items.forEach((item) => {
      const toggle = item.querySelector(selectors.toggle);
      const panel = item.querySelector(selectors.panel);

      toggle.removeAttribute('aria-expanded');
      toggle.removeAttribute('aria-controls');

      panel.removeAttribute('hidden');
    });

    this.instances.forEach((instance) => instance.destroy());
    this.instances = [];
  }
}

Accordion.DEFAULTS = {
  mq: '',
  selectors: {
    container: '.js-Accordion',
    items: '.js-Accordion-item',
    toggle: '.js-Accordion-toggle',
    panel: '.js-Accordion-panel',
  },
};

window.addEventListener('load', (event) => {
  document
    .querySelectorAll(Accordion.DEFAULTS.selectors.container)
    .forEach((accordion) => new Accordion(accordion));
});

export default Accordion;
