const history = ( navigation, timelineItems ) => {
  const observer = new IntersectionObserver(handleIntersect, {
    root: null,
    rootMargin: '-50% 0px',
    threshold: 0
  });

  function handleIntersect( entries, observer ){
    entries.forEach((entry) => {
      if( entry.isIntersecting ) {
        const element = entry.target;
        const link = navigation.querySelector(`[href="#${element.id}"]`);
        const { x, width } = link.getBoundingClientRect();
        const half = width / 2;

        navigation.querySelectorAll('a').forEach( element => element.classList.remove('is-active') );
        link.classList.add('is-active');

        if( (x + half) > (window.innerWidth / 2) || x < 0 ) {
          navigation.scrollTo({
            top: 0,
            left: navigation.scrollLeft + x,
            behavior: 'smooth'
          })
        }        
      }
    });
  }

  function handleClick( event ){
    event.preventDefault();

    const link = event.target;
    const target = Array.from(timelineItems).find( item => 
      item.id === link.getAttribute("href").substring(1)
    );

    const targetRect = target.getBoundingClientRect();
    const absoluteElementTop = targetRect.height > window.innerHeight ? 
      targetRect.top + window.pageYOffset : 
      targetRect.top + (targetRect.height / 2) + window.pageYOffset;

    const yPos = targetRect.height > window.innerHeight ? 
      absoluteElementTop : 
      absoluteElementTop - (window.innerHeight / 2);

    window.scrollTo(0, yPos);
  }

  timelineItems.forEach( element => {
    observer.observe( element );
  });

  navigation.querySelectorAll('a').forEach( element => {
    element.addEventListener('click', handleClick);
  });
}

export default {
  init() {
    const navigation = document.querySelector('.js-HistoryNav');
    const timelineItems = document.querySelectorAll('.js-HistoryItem');

    if ( timelineItems.length ) {
      history( navigation, timelineItems );
    }
  }
}