import MicroModal from 'micromodal';

MicroModal.init({
  disableScroll: true,
  disableFocus: true,
  onShow: modal => {
    document.dispatchEvent(new Event('modal-open'));
  },
  onClose: (modal, element, event) => {
    event.preventDefault();
    event.stopPropagation();
    document.dispatchEvent(new Event('modal-close'));
  }
});