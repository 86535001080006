import { siblings } from '@glidejs/glide/src/utils/dom';

function ResizeSlider(Glide, Components, Events, classes, selectors) {
  var Component = {
    mount() {
      this.changeActiveSlide();
      this.updateTrackHeight();
    },

    changeActiveSlide() {
      let slide = Components.Html.slides[Glide.index];
      slide.classList.add(classes.glideSlideNextActive);

      siblings(slide).forEach((sibling) => {
        sibling.classList.remove(classes.glideSlideNextActive);
      });
    },

    updateTrackHeight() {
      // console.log('update track height');

      const activeSlide = document.querySelector(
        selectors.glideSlideNextActive
      );
      const activeSlideHeight = activeSlide ? activeSlide.offsetHeight : 0;

      const glideTrack = document.querySelector(selectors.glideTrack);
      const glideTrackHeight = glideTrack ? glideTrack.offsetHeight : 0;

      // console.log(`Active slide: ${activeSlide} activeSlideHeight: ${activeSlideHeight}`)

      if (activeSlideHeight !== glideTrackHeight) {
          glideTrack.style.height = `${activeSlideHeight}px`;
      }
    },
  };

  Events.on('run', () => {
    Component.changeActiveSlide();
    Component.updateTrackHeight();
  });

  return Component;
};

export default ResizeSlider;